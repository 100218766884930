<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isNoSaved"
    @close="
      () => {
        isNoSaved = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-500 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения будут утерянны если их не сохранить, все равно хотите уйти?
      </div>

      <div class="grid gtc-2 ggap-10 w-100">
        <button class="btn transparent-grey jc-c" @click="$router.push(leaveRoute)">
          <BaseIcon class="ic-16 red" icon="close-circle" />
          Не сохранять и уйти
        </button>
        <button class="btn green jc-c" @click="submitAction(false)">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Сохранить изменения
        </button>
      </div>
    </div>
  </ModalEmpty>

  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <div class="flex ai-c ggap-10">
          <BaseIcon class="ic-24 primary" icon="edit" />
          <h1 class="title">Редактирование заявки №{{ route.params.id }}</h1>
        </div>

        <!-- Status -->
        <small
          v-if="formData.status"
          class="marker"
          :style="{
            color: formData.status && formData.status.text_color,
            backgroundColor: formData.status && formData.status.background_color
          }"
        >
          {{ (formData.status && formData.status.name) || '&mdash;' }}
        </small>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <!-- <div class="form-edit grid gtc-1-auto"> -->
      <form class="form-edit pb-40 pos-r z0" id="form-edit" @submit.prevent>
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <template
          v-if="
            isAuthor &&
            formData.status &&
            (formData.status.uid === 'draft' || formData.status.uid === 'new' || formData.status.uid === 'deleted')
          "
        >
          <!-- Основные данные -->
          <section class="form-edit__section grid ggap-20">
            <h2 class="title">Основные данные</h2>
            <div class="grid gtc-2 ggap-20 ai-fs">
              <FormAutocomplete
                label="Тип заявки"
                :helper="`<p><b>Создать документ</b> - процесс создание документа с нуля, для точного формирование документа укажите особые условия.</p><p><b>Согласовать документ</b> - процесс состоит из этапов рассмотрение и согласование, можете в общих комментариях указать заинтересованных лиц для рассмотрение и согласование.</p>`"
                required
                :options="optionsOrderType"
                :isSearch="false"
                v-model="formData.type"
              />
              <FormAutocomplete
                label="Проект"
                :helper="`<p><b>Проект это</b> - не официальное наименование дочерней компании Холдинга.</p><p>Укажите к какому проекту относится данный документ.</p>`"
                required
                :options="optionsProjects"
                v-model="formData.project"
              />
            </div>

            <FormEditor
              label="Особые условия"
              helper="Например: пожелание, условия оплаты, ответственность и т.д."
              placeholder="Пример: любые нюансы, ответственность, пожелания Инициатора и т.д."
              class="big"
              required
              v-model="formData.special_conditions"
            />
          </section>

          <!-- Файлы -->
          <section class="form-edit__section grid ggap-20">
            <div class="flex ai-c ggap-10">
              <h2 class="title">Файлы заявки</h2>
              <BaseIcon
                class="form-item__help ic-16 grey"
                icon="alert"
                v-tippy="'Прикрепите те файлы которые будут участвовать в создании или рассмотрении документа'"
              />
            </div>
            <FormFile v-model="formData.documents" />
          </section>

          <!-- Данные автора -->
          <section class="form-edit__section grid ggap-20">
            <h2 class="title">Ваши данные</h2>
            <div class="flex ai-c ggap-10 fw">
              <span
                v-for="contact of contacts"
                :key="contact.id"
                class="box pt-5 pb-5 pl-10 pr-10 fs-14 flex ai-c ggap-5"
                v-tippy="'Ваш личный контакт из вашего профиля'"
              >
                <BaseIcon class="ic-18 grey" :icon="contact.type" />
                {{ contact.value }}
              </span>
              <span
                v-for="(contact, index) in formData.newContactList"
                :key="contact.value"
                class="box pt-5 pb-5 pl-10 pr-5 fs-14 flex ai-c ggap-10"
                v-tippy="'Новый контакт'"
              >
                <div class="flex ai-c ggap-5">
                  <BaseIcon class="ic-18 primary" :icon="contact.type" />
                  {{ contact.value }}
                </div>
                <button class="btn xsm transparent-grey rounded cube-20" @click="removeContact(index)">
                  <BaseIcon class="ic-14 red" icon="close" />
                </button>
              </span>

              <!-- Новый контакт -->
              <div v-if="isAddContact" class="new-contact grid gtc-auto-1-auto ai-c ggap-5 box p-5 w-100">
                <FormSelect
                  class="sm"
                  label=""
                  placeholder=""
                  :options="contactTypesOptions"
                  v-model="newContact.type"
                  required
                  @keydown.enter.prevent
                />
                <FormInput
                  class="sm w-100"
                  label=""
                  placeholder="Введите"
                  v-model="newContact.value"
                  @keydown.enter.prevent
                  required
                />
                <div class="flex ai-c ggap-5">
                  <button class="btn primary-light sm" @click="addNewContact">Сохранить</button>
                  <button class="btn cancel sm" @click="isAddContact = false">Отмена</button>
                </div>
              </div>

              <!-- Добавить контакт -->
              <button
                v-else
                class="btn primary-light sm"
                v-tippy="
                  'Можно добавить контакты свои или другого участника, эти контакты НЕ будут сохранены в ваш профиль'
                "
                @click="isAddContact = !isAddContact"
              >
                <BaseIcon class="ic-16 primary" icon="plus-circle" /> Добавить контакты
              </button>
            </div>
            <!-- <div class="grid gtc-2">
                <FormDate label="Дедлайн" v-model="formData.deadline_date" />
              </div> -->
            <FormEditor
              label="Общий комментарий"
              helper="Свободный текст, пример: Свяжитесь с Васей по этим контактам +7ххх он лучше знает что нужно."
              placeholder=""
              class="big"
              v-model="formData.comments"
            />
          </section>

          <!-- Контрагент и сумма -->
          <section class="form-edit__section grid ggap-20">
            <h2 class="title">Дополнительно можете указать (не обязательно)</h2>

            <!-- Сумма -->
            <div class="form-edit__section-box grid">
              <button
                class="form-edit__section-box-btn btn flex ai-c jc-c"
                :class="{ active: isSummDoc, outline: isSummDoc, 'primary-light': !isSummDoc }"
                @click.prevent="isSummDoc = !isSummDoc"
              >
                <BaseIcon v-if="isSummDoc" class="ic-16 red" icon="delete" />
                <BaseIcon v-else class="ic-16 primary" icon="plus-circle" />
                {{ isSummDoc ? 'Убрать сумму договора' : 'Добавить сумму договора' }}
              </button>

              <div v-if="isSummDoc" class="form-edit__section-box-body grid ggap-10">
                <BaseAlert type="yellow">
                  Если вы знаете <b>Стоимость договора</b> то выберите его и впишите стоимость. Если вы не знаете
                  стоимость договора то выберите <b>Оборот за 1 год</b> и впишите предпологаемую стоимость.
                </BaseAlert>
                <div class="grid gtc-1-auto ggap-20">
                  <FormRadioGroup :items="summTypeItems" v-model.number="formData.priceType" />
                  <FormSumm
                    label=""
                    placeholder="1 500 000"
                    required
                    v-model="formData.price"
                    v-model:currencyValue="formData.currency"
                  />
                </div>
              </div>
            </div>

            <!-- Контрагент -->
            <div class="form-add__section-box grid">
              <div class="grid ggap-20">
                <div class="flex ai-c jc-sb">
                  <h3 class="m-0">Контрагент</h3>
                  <button
                    type="button"
                    @click.prevent.stop="isNewCounterparty = !isNewCounterparty"
                    class="btn transparent-grey sm pl-10"
                  >
                    <FormFakeSwitch :isTrue="isNewCounterparty" />
                    Добавить нового
                  </button>
                </div>

                <div v-if="isNewCounterparty" class="grid gtc-2 ggap-20 ai-fs">
                  <FormInput
                    label="БИН / ИИН"
                    placeholder="Введите БИН или ИИН"
                    v-model="formData.counterparty_bin"
                    :maxlength="12"
                    required
                  />
                  <FormInput
                    label="Юр. наименование контрагента"
                    placeholder="Введите наименование контрагента"
                    v-model="formData.counterparty_name"
                    required
                  />
                </div>
                <div v-else class="grid gtc-2 ggap-20 ai-fs">
                  <FormAutocompleteCounterparty
                    class="f-1"
                    label="БИН / ИИН"
                    placeholder="Выберите"
                    :options="optionsCounterparties"
                    :isLoad="isCounterpartyLoad"
                    :isMultiple="false"
                    required
                    @search="searchCounterparties($event)"
                    v-model="formData.counterparty"
                  >
                    <template #single="{ item }">
                      <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
                        <div class="grid gtc-auto-1 ai-c ggap-10">
                          <div class="cut">{{ item.text }}</div>
                        </div>
                      </div>
                      <div v-else class="t-grey-dark">-</div>
                    </template>
                  </FormAutocompleteCounterparty>
                  <ItemRead label="Юр. наименование контрагента">
                    <div
                      class="flex ai-c pt-10 fw-b"
                      :class="{ 't-grey-dark': !(formData.counterparty && formData.counterparty.name) }"
                    >
                      {{
                        formData.counterparty && formData.counterparty.name
                          ? formData.counterparty.name
                          : 'Нет наименования'
                      }}
                    </div>
                  </ItemRead>
                </div>

                <FormTextarea
                  label="Реквизиты контрагента"
                  placeholder="Реквизиты и / или контактная информация"
                  class="big"
                  required
                  v-model="formData.counterparty_info"
                />
              </div>
            </div>
          </section>
        </template>

        <!-- Юзеры (только для юристов) -->
        <section v-if="isAnyLawyer" class="form-edit__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title mb-5">Выбор исполнителя и других участников заявки</h2>
            <small class="marker red">Только для Юристов</small>
          </div>
          <Peoples
            v-if="peoplesSingle && !isLoad"
            :isRead="false"
            :isFree="isFree"
            :objUsers="peoplesSingle"
            :freeUsers="peoplesList"
            :departmentUsers="peoplesDepartments"
          />
        </section>
      </form>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-sb ggap-20">
        <button class="btn grey" @click="closeAction">
          <BaseIcon class="ic-16 primary" icon="arrow-left" />
          Отменить
        </button>

        <button v-if="isNotDraft" class="btn green" :disabled="isChanged" @click="submitAction(false)">
          <!-- :class="{ disabled: !isChanged }" -->
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Сохранить изменения
        </button>

        <div v-else class="flex ai-c ggap-10">
          <button class="btn primary-light" @click="submitAction(true)">
            <BaseIcon class="ic-16 primary" icon="doc-2" />
            Сохранить черновик
          </button>
          <button class="btn primary" @click="submitAction(false)">
            <BaseIcon class="ic-16 white" icon="check-circle" />
            Отправить Юристам
          </button>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { ref, watch, computed, nextTick } from 'vue'
import {
  FormAutocompleteCounterparty,
  FormAutocomplete,
  FormRadioGroup,
  FormFakeSwitch,
  FormTextarea,
  ModalWrapper,
  ModalEmpty,
  FormSelect,
  FormEditor,
  BaseAlert,
  FormInput,
  FormSumm,
  FormFile,
  BaseLoad,
  BaseIcon,
  Peoples,
  ItemRead
} from '@/components'
import {
  isInvalidForm,
  $busEmit,
  preparingPeoplesList,
  preparingPeoplesSingle,
  preparingPeoplesDepartments,
  disableAllBtns
} from '@/plugins'
import orders from '@/api/modules/orders'
import { reject } from 'q'

// Data
const store = useStore()
const route = useRoute()
const router = useRouter()

// Boolean
const leaveRoute = ref('')
const isNoSaved = ref(false)
const isLoad = ref(false)
const isSummDoc = ref(false)
const isCounterparty = ref(false)
const isNewCounterparty = ref(false)
const isFree = ref({
  reviewers: false,
  agreementers: false,
  watchers: false,
  final_boss: true
})

// Form data
const formDataNochanged = ref(null)
const formData = ref({
  author: null,
  status: null,
  type: {}, // matching || create
  project: {},
  priceType: 'contract_price',
  price: '',
  currency: 'kzt', // kzt || usd
  special_conditions: '',
  counterparty: {},
  counterparty_id: '',
  counterparty_name: '',
  counterparty_bin: '',
  counterparty_info: '',
  comments: '',
  // deadline_date: '',
  documents: [],
  newContactList: []
})
const summTypeItems = [
  {
    text: 'Стоимость договора',
    value: 'contract_price'
  },
  {
    text: 'Оборот за 1 год',
    value: 'annual_turnover'
  }
]
const optionsOrderType = [
  {
    text: 'Создать документ',
    value: 'create'
  },
  {
    text: 'Согласовать документ',
    value: 'matching'
  }
]

// Contacts data
const isAddContact = ref(false)
const newContact = ref({
  type: 'phone',
  value: ''
})
const contactTypesOptions = [
  {
    id: 2,
    text: 'Телефон',
    value: 'phone'
  },
  {
    id: 3,
    text: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    id: 4,
    text: 'Telegarm',
    value: 'telegram'
  },
  {
    id: 5,
    text: 'E-mail',
    value: 'email'
  }
]

// Peoples data
const peoplesList = ref(null)
const peoplesSingle = ref(null)
const peoplesDepartments = ref(null)

const peoplesListNochanged = ref(null)
const peoplesSingleNochanged = ref(null)
const peoplesDepartmentsNochanged = ref(null)

// Computed
const isNotDraft = computed(() => formData.value?.status?.uid !== 'draft')
const isChanged = computed(() => {
  const res = []

  // FormData
  const oldCols = formDataNochanged.value ? cloneDeep(formDataNochanged.value) : {}
  const newCols = formData.value ? cloneDeep(formData.value) : {}
  res.push(isEqual(oldCols, newCols))

  // People list
  const oldPL = peoplesListNochanged.value ? peoplesListNochanged.value : {}
  const newPL = peoplesList.value ? peoplesList.value : {}
  res.push(isEqual(oldPL, newPL))

  // People single
  const oldPLS = peoplesSingleNochanged.value ? peoplesSingleNochanged.value : {}
  const newPLS = peoplesSingle.value ? peoplesSingle.value : {}
  res.push(isEqual(oldPLS, newPLS))

  // People departments
  const oldPLD = peoplesDepartmentsNochanged.value ? peoplesDepartmentsNochanged.value : {}
  const newPLD = peoplesDepartments.value ? peoplesDepartments.value : {}
  res.push(isEqual(oldPLD, newPLD))

  return !res.some((el) => el === false)
})
const contacts = computed(() => store.getters?.profile?.contacts || [])
const optionsProjects = computed(() => store.getters.projects || [])
const optionsCounterparties = computed(() => store.getters.counterparties || [])
const isAuthor = computed(() => store.getters?.profile?.id === formData.value?.author?.id)
const isAnyLawyer = computed(
  () => store.getters?.profile?.lawyer_role === 'Senior Lawyer' || store.getters?.profile?.lawyer_role === 'Lawyer'
)

// Watcher
watch(
  () => formData.value.counterparty,
  () => {
    formData.value.counterparty_info = formData.value.counterparty?.info || formData.value.counterparty_info
    formData.value.counterparty_name = formData.value.counterparty?.name || formData.value.counterparty_name
  }
)
watch(isLoad, (val) => {
  disableAllBtns('.modal', val)
})

// Created
getData()

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (!isChanged.value && !leaveRoute.value) {
    isNoSaved.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods
function closeAction() {
  if (leaveRoute.value) router.replace(leaveRoute.value)
  else router.replace('/orders/view/' + route.params.id)
}
async function getData() {
  isLoad.value = true
  await store.dispatch('orders/GET_ITEM', route?.params?.id)
  await store.dispatch('GET_USERS')
  await store.dispatch('GET_PROJECTS')
  await store.dispatch('GET_COUNTERPARTIES')
  await getItem()
  isLoad.value = false
}
async function getItem() {
  isLoad.value = true
  await store.dispatch('orders/GET_ITEM', route.params.id)
  const item = await store.getters['orders/item']

  if (item) {
    // Preparing data
    formData.value.author = item?.author
    formData.value.status = item?.status
    formData.value.type = optionsOrderType.find((el) => el.value === item?.type)
    formData.value.project = item?.project
      ? {
          id: item.project.id,
          item: item.project,
          text: item.project.project,
          value: item.project.id
        }
      : {}
    formData.value.priceType = Number(item.annual_turnover)
      ? 'annual_turnover'
      : Number(item.contract_price)
      ? 'contract_price'
      : 'contract_price'
    const price = Number(item.contract_price) || Number(item.annual_turnover)
    formData.value.price = String(price)
    formData.value.currency = item.currency || 'kzt'
    isSummDoc.value = !!Number(formData.value.price)

    formData.value.special_conditions = item.special_conditions
    formData.value.comments = item.comments || ''

    isCounterparty.value = !!item.counterparty?.id
    formData.value.counterparty = item?.counterparty
      ? optionsCounterparties.value.find((el) => el.id === item.counterparty?.id)
      : ''
    formData.value.counterparty_bin = item.counterparty ? item.counterparty_bin : ''
    formData.value.counterparty_name = item.counterparty ? item.counterparty_name : ''
    formData.value.counterparty_info = item.counterparty ? item.counterparty_info : ''

    formData.value.documents = item?.documents.map((el) => {
      return {
        file: el,
        id: el.id,
        mime: el.mime,
        name: el.name,
        size: el.size
      }
    })

    const contacts = []
    for (const key in item?.contact_information) {
      if (Object.hasOwnProperty.call(item.contact_information, key)) {
        const value = item.contact_information[key]
        contacts.push({ type: key, value })
      }
    }
    formData.value.newContactList = contacts

    // Set peoples
    peoplesList.value = await preparingPeoplesList(item)
    peoplesSingle.value = await preparingPeoplesSingle(item)
    peoplesDepartments.value = await preparingPeoplesDepartments(item)

    // Set peoples dublicate
    peoplesListNochanged.value = cloneDeep(peoplesList.value)
    peoplesSingleNochanged.value = cloneDeep(peoplesSingle.value)
    peoplesDepartmentsNochanged.value = cloneDeep(peoplesDepartments.value)

    // Set dublicate
    formDataNochanged.value = cloneDeep(formData.value)
  }

  isLoad.value = false
}
async function submitAction(isDraft = false) {
  isNoSaved.value = false
  if (!isDraft && isInvalidForm('form-edit')) return

  isLoad.value = true
  await store.dispatch('orders/GET_ITEM', route.params.id)
  const checkOrder = store.getters['orders/item']
  if (checkOrder.status?.id !== formData.value.status?.id) {
    $busEmit('setToast', {
      type: 'red',
      icon: 'alert-triangle',
      title: 'Нельзя сохранить',
      message: 'Данную заявку уже кто-то изменил, и потому вы не можете сохранить свои изменения!'
    })
    return
  }

  try {
    await allActions()

    // Если был черновик но отправили Юристам
    if (!isDraft && formData.value.status.uid === 'draft') await orders.updateStatus(route.params.id, { status_id: 1 })

    if (leaveRoute.value) {
      router.replace(leaveRoute.value)
    } else {
      leaveRoute.value = '/orders/view/' + route.params.id
      await nextTick()
      router.replace(leaveRoute.value)
    }
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно обновлена, можете продолжать работу.'
    })
  } finally {
    isLoad.value = false
  }
}
async function allActions() {
  try {
    await updateResponsible()
    await updateAgreementers()
    await updatePeoplesFree()
    await updatePeoplesDepartments()

    // Если были изменения в заявке
    const changedData = getChangedData()
    if (changedData.length) {
      const formDataFinal = new FormData()
      formDataFinal.append('_method', 'PATCH')
      for (let i = 0; i < changedData.length; i++) {
        const item = changedData[i]
        formDataFinal.append(item.key, item.value)
      }

      await orders.update(route.params.id, formDataFinal)
    }
  } catch (error) {
    console.log(error)
    return reject()
  }
}

// Methods:preparing
function getChangedData() {
  let res = []

  // Type
  if (formDataNochanged.value.type?.value !== formData.value.type?.value) {
    res.push({ key: 'type', value: formData.value.type?.value })
  }

  // Project
  if (formDataNochanged.value.project?.id !== formData.value.project?.id) {
    res.push({ key: 'project_id', value: formData.value.project?.id })
  }

  // Price and price type
  if (isSummDoc.value) {
    if (
      Number(formDataNochanged.value.price) !== Number(formData.value?.price) ||
      formDataNochanged.value.priceType !== formData.value?.priceType
    ) {
      res.push({ key: formData.value?.priceType, value: Number(formData.value?.price.replace(/\s/g, '')) + '.00' })
      res.push({
        key: formData.value?.priceType === 'contract_price' ? 'annual_turnover' : 'contract_price',
        value: '0.00'
      })
    }
  } else {
    if (Number(formDataNochanged.value.price) > 0) {
      res.push({ key: 'annual_turnover', value: '0.00' })
      res.push({ key: 'contract_price', value: '0.00' })
    }
  }

  // Currency
  if (formDataNochanged.value.currency !== formData.value.currency && isSummDoc.value) {
    res.push({ key: 'currency', value: formData.value.currency })
  }

  // Special conditions
  if (formDataNochanged.value.special_conditions !== formData.value.special_conditions) {
    const special_conditions = formData.value.special_conditions.split('<p>&nbsp;</p>').join('')
    res.push({ key: 'special_conditions', value: special_conditions })
  }

  // If new counterparty
  if (isNewCounterparty.value) {
    res.push({ key: 'counterparty_bin', value: formData.value.counterparty_bin })
    res.push({ key: 'counterparty_name', value: formData.value.counterparty_name })
    res.push({ key: 'counterparty_info', value: formData.value.counterparty_info })
  } else {
    if (
      formDataNochanged.value.counterparty_name !== formData.value.counterparty_name ||
      formDataNochanged.value.counterparty_info !== formData.value.counterparty_info ||
      formDataNochanged.value.counterparty_bin !== formData.value.counterparty_bin ||
      formDataNochanged.value.counterparty?.id !== formData.value.counterparty?.id
    ) {
      res.push({
        key: 'counterparty_bin',
        value: formData.value.counterparty?.value || formData.value.counterparty_bin
      })
      res.push({ key: 'counterparty_name', value: formData.value.counterparty_name })
      res.push({ key: 'counterparty_info', value: formData.value.counterparty_info })
      res.push({ key: 'counterparty_id', value: formData.value.counterparty.id })
    }
  }

  // Documents
  if (formDataNochanged.value.documents?.length || formData.value.documents?.length) {
    // New docs
    for (let i = 0; i < formData.value.documents.length; i++) {
      const item = formData.value.documents[i]
      const isNope = formDataNochanged.value?.documents?.findIndex((el) => el.id === item.id) === -1
      if (isNope) res.push({ key: 'documents[]', value: item.file })
    }

    // Old docs
    for (let i = 0; i < formDataNochanged.value.documents.length; i++) {
      const item = formDataNochanged.value.documents[i]
      const isNope =
        formData.value.documents?.length && formData.value.documents.findIndex((el) => el.id === item.id) === -1
      if (isNope) res.push({ key: 'deletable_documents[]', value: item.id })
    }
  }

  // Comments
  if (formDataNochanged.value.comments !== formData.value.comments) {
    const comments = formData.value.comments.split('<p>&nbsp;</p>').join('')
    res.push({ key: 'comments', value: comments })
  }

  // New contacts
  if (formData.value.newContactList?.length) {
    for (let i = 0; i < formData.value.newContactList.length; i++) {
      const item = formData.value.newContactList[i]
      res.push({ key: `contacts[${item.type}]`, value: item.value })
    }
  } else if (formDataNochanged.value.newContactList?.length) {
    res.push({ key: `contacts`, value: '' })
  }

  return res
}
function preparingPayloadDepartments() {
  const payloadDepartments = {
    review_departments: [],
    watcher_departments: []
  }

  for (const key in isFree.value) {
    if (Object.hasOwnProperty.call(isFree.value, key)) {
      const keyDepartment = key === 'reviewers' ? 'review_departments' : 'watcher_departments'
      const currentDepartments = peoplesDepartments.value[key] || []
      const oldDepartments = peoplesDepartmentsNochanged.value[key] || []

      if (!isFree.value[key] && key !== 'final_boss' && key !== 'agreementers') {
        // ### Бежим по текущим отделам ###
        if (currentDepartments.length) {
          for (let i = 0; i < currentDepartments.length; i++) {
            const item = currentDepartments[i]
            const payload = {
              department_id: item.id,
              new_department_users: [],
              deletable_department_users: [],
              new_manual_users: [],
              deletable_manual_users: []
            }

            // Ищем старый отдел
            const oldItem = oldDepartments.find((el) => el.id === item.id)

            // Если нет старого отдела
            if (!oldItem) {
              payload.new_department_users = item.users.map((el) => el.id)
              payload.new_manual_users = item.manual_users.map((el) => el.id)
            }

            // Если есть новые кастомные юзеры
            else {
              const manualUsers = item.manual_users.map((el) => el.id)
              const oldManualUsers = oldItem.manual_users.map((el) => el.id)
              payload.new_manual_users = manualUsers.filter((el) => !oldManualUsers.includes(el))
              payload.deletable_manual_users = oldManualUsers.filter((el) => !manualUsers.includes(el))
            }

            if (
              payload.new_department_users.length ||
              payload.deletable_department_users.length ||
              payload.new_manual_users.length ||
              payload.deletable_manual_users.length
            ) {
              payloadDepartments[keyDepartment].push(payload)
            }
          }
        }

        // ### Бежим по старым отделам и проверяем удалили ли что нибудь ###
        const cdID = currentDepartments.map((el) => el.id)
        const odID = oldDepartments.map((el) => el.id)
        const hasDeleted = odID.filter((el) => !cdID.includes(el))
        if (hasDeleted.length) {
          for (let i = 0; i < hasDeleted.length; i++) {
            const id = hasDeleted[i]
            const payload = {
              department_id: id,
              new_department_users: [],
              deletable_department_users: [],
              new_manual_users: [],
              deletable_manual_users: []
            }

            // Ищем старый отдел
            const oldItem = oldDepartments.find((el) => el.id === id)

            // Вставляем удаленных юзеров
            if (oldItem) {
              payload.deletable_department_users = oldItem.users.map((el) => el.id)
              payload.deletable_manual_users = oldItem.manual_users.map((el) => el.id)
            }

            payloadDepartments[keyDepartment].push(payload)
          }
        }
      }

      // Проверяем надо ли удалить старые записи
      else if (isFree.value[key] && key !== 'final_boss' && key !== 'agreementers' && oldDepartments.length) {
        for (let i = 0; i < oldDepartments.length; i++) {
          const item = oldDepartments[i]
          const payload = {
            department_id: item.id,
            new_department_users: [],
            deletable_department_users: item.users.map((el) => el.id),
            new_manual_users: [],
            deletable_manual_users: item.manual_users.map((el) => el.id)
          }
          payloadDepartments[keyDepartment].push(payload)
        }
      }
    }
  }

  return payloadDepartments
}
function preparingPayloadFree() {
  const payloadFree = {
    new_reviewers: [],
    deletable_reviewers: [],
    new_watchers: [],
    deletable_watchers: []
  }

  for (const key in isFree.value) {
    if (Object.hasOwnProperty.call(isFree.value, key)) {
      const currentFree = peoplesList.value[key] || []
      const oldFree = peoplesListNochanged.value[key] || []

      // Если свободный выбор
      if (isFree.value[key] && key !== 'final_boss' && key !== 'agreementers') {
        // ### Бежим по текущим юзерам ###
        if (currentFree.length) {
          // New
          for (let i = 0; i < currentFree.length; i++) {
            const item = currentFree[i]
            const isNope = oldFree.findIndex((el) => el.id === item.id) === -1
            if (isNope) payloadFree['new_' + key].push(item.id)
          }
        }

        // ### Бежим по старым юзерам и проверяем удалили ли что нибудь ###
        if (oldFree.length) {
          // Old
          for (let i = 0; i < oldFree.length; i++) {
            const item = oldFree[i]
            const isNope = currentFree.findIndex((el) => el.id === item.id) === -1
            if (isNope) payloadFree['deletable_' + key].push(item.id)
          }
        }
      }

      // Проверяем надо ли удалить старые записи
      else if (!isFree.value[key] && key !== 'final_boss' && key !== 'agreementers') {
        for (let i = 0; i < oldFree.length; i++) {
          const item = oldFree[i]
          payloadFree['deletable_' + key].push(item.id)
        }
      }
    }
  }

  return payloadFree
}

// Methods:peoples
function updatePeoplesFree() {
  const payload = preparingPayloadFree()

  // Final boss
  if (peoplesSingleNochanged.value.final_boss?.id !== peoplesSingle.value.final_boss?.id) {
    payload.final_boss = peoplesSingle.value.final_boss?.id
  }

  // Clear payload
  if (!payload.new_reviewers?.length) delete payload.new_reviewers
  if (!payload.deletable_reviewers?.length) delete payload.deletable_reviewers
  if (!payload.new_watchers?.length) delete payload.new_watchers
  if (!payload.deletable_watchers?.length) delete payload.deletable_watchers

  // Send promise
  return new Promise((resolve) => {
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoples(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updatePeoplesDepartments() {
  const payload = preparingPayloadDepartments()

  // Clear payload
  if (!payload.review_departments?.length) delete payload.review_departments
  if (!payload.watcher_departments?.length) delete payload.watcher_departments

  // Send promise
  return new Promise((resolve) => {
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoplesDepartments(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updateAgreementers() {
  const isChangedAgree = []
  const oldPL = peoplesListNochanged.value?.agreementers?.map((el) => el.id)
  const newPL = peoplesList.value?.agreementers?.map((el) => el.id)
  const oldDP = peoplesDepartmentsNochanged.value?.agreementers
  const newDP = peoplesDepartments.value?.agreementers

  if (oldPL.length || newPL.length) isChangedAgree.push(!isEqual(newPL, oldPL))
  if (oldDP.length || newDP.length) isChangedAgree.push(!isEqual(newDP, oldDP))
  // isChangedAgree.push(isFree.agreementers && !!oldDP.length)

  const payload = {
    agreementers: [],
    agreement_departments: [],
    deletable_departments: []
  }

  if (isChangedAgree.some((el) => el)) {
    const oldDepartsFlat = oldDP.map((el) => el.id)

    // Free
    if (isFree.value.agreementers) {
      for (let i = 0; i < peoplesList.value.agreementers.length; i++) {
        const element = peoplesList.value.agreementers[i]
        payload.agreementers.push({
          id: element.id,
          index: i
        })
      }

      payload.deletable_departments = oldDepartsFlat
    }

    // Departments
    if (!isFree.value.agreementers && !isEqual(newDP, oldDP)) {
      for (let i = 0; i < peoplesDepartments.value.agreementers.length; i++) {
        const element = peoplesDepartments.value.agreementers[i]
        payload.agreement_departments.push({
          department_id: element.id,
          department_index: i,
          manual_users: element.manual_users.map((el, index) => {
            return {
              id: el.id,
              index
            }
          })
        })
      }

      const curDeparts = newDP.map((el) => el.id)
      payload.deletable_departments = oldDepartsFlat?.length
        ? oldDepartsFlat.filter((el) => !curDeparts.includes(el))
        : []
    }
  }

  // Cleaning payload
  if (!payload.agreementers.length) delete payload.agreementers
  if (!payload.agreement_departments.length) delete payload.agreement_departments
  if (!payload.deletable_departments.length) delete payload.deletable_departments

  // Send promise
  return new Promise((resolve) => {
    // If has payload send request
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoplesAgree(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updateResponsible() {
  // Send promise
  return new Promise((resolve) => {
    if (peoplesSingleNochanged.value?.responsible?.id !== peoplesSingle.value?.responsible?.id) {
      resolve(orders.updateResponsible(route.params.id, { responsible_id: peoplesSingle.value.responsible?.id }))
    } else {
      resolve()
    }
  })
}

// Methods:contacts
function removeContact(index) {
  formData.value.newContactList.splice(index, 1)
}
function addNewContact() {
  formData.value.newContactList.push({ ...newContact })
  newContact.value.type = 'phone'
  newContact.value = ''
  isAddContact.value = false
}
</script>

<style lang="scss" scoped>
.form-edit {
  min-height: calc(100vh - 300px);
  width: 800px;
  position: relative;

  // &__right {
  //   width: 300px;
  //   background-color: var(--form);
  //   border-left: var(--b1);

  //   &-help {
  //     position: sticky;
  //     top: 60px;
  //     z-index: 0;
  //   }
  // }

  &__section {
    padding: 20px;
    // border-bottom: var(--b1);

    &:last-child {
      border: 0;
    }

    .title {
      color: var(--green);
    }

    &-box {
      &-btn {
        &.active {
          background-color: var(--grey);
          border-radius: var(--br) var(--br) 0 0;
        }
      }
      &-body {
        background-color: var(--box);
        border-radius: 0 0 var(--br) var(--br);
        border: var(--b1);
        border-top: 0;
        padding: 20px;
      }
    }
  }
}
</style>
